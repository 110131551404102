export function headeraffix($) {
  $('#portal-top').on('affixed.bs.affix', function() {
    $('body').addClass('header-affixed');
  });

  $('#portal-top').on('affixed-top.bs.affix', function() {
    $('body').removeClass('header-affixed');
  });

  $('#portal-top').affix({
    offset: {
      top: 1,
      bottom: 0,
    },
  });
}
