import $ from 'jquery';
import { affix } from './affix';
import { headeraffix } from './headeraffix';
import { returntotop } from './returntotop';
import { search } from './search';
import { mobilemenu } from './mobilemenu';

$(() => {
  const options = {
    screen_sm_min: 768,
    screen_sm_max: 767,
  };

  affix($);
  returntotop($);
  search($);
  mobilemenu($);
  headeraffix($);
});
