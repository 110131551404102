export function mobilemenu($) {
  function openMobileMenu() {
    $('#portal-header').addClass('open');
    $('body').addClass('menu-open');
  }

  function closeMobileMenu() {
    $('#portal-header').removeClass('open');
    $('body').removeClass('menu-open');
  }

  $('#portal-header button.toggle-menu-button').click(function() {
    if (!$('#portal-header').hasClass('open')) {
      openMobileMenu();
    } else {
      closeMobileMenu();
    }
  });

  // gestione click out per chiudere menu
  $(document).click(function(e) {
    if (
      !$(e.target).closest('#portal-header').length > 0 &&
      $('#portal-header').hasClass('open') &&
      !$(e.target).closest('button.toggle-menu-button').length > 0
    ) {
      closeMobileMenu();
    } else if (
      $(e.target).closest('button.search-button').length > 0 &&
      $('#portal-header').hasClass('open')
    ) {
      // la ricerca è apribile col menu aperto, in quel caso va chiuso
      closeMobileMenu();
    }
  });
}
